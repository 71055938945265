import React from "react";

import profile_picture from '../../../../assets/asia_ccs_speaker.jpg'
import '../../../css/Main.css'

class Introduction extends React.Component {

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'instant'});
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.screen_size === 'large' || this.props.orientation === 'horizontal') &&
                    <React.Fragment>
                        <h3 className={'introduction_heading_horizontal heading2_' + this.props.styling + ' heading2_'+this.props.screen_size}>Hello my name
                            is Florian Frank</h3>
                        <br/>
                    </React.Fragment>
                }

                <div className={'start_page_div_container_' + this.props.screen_size + "_" + this.props.orientation}>
                    <div
                        className={'div_profile_picture-first-page_' + this.props.screen_size + (this.props.screen_size !== 'large' ?
                            ' div_profile_picture-first-page_' + this.props.screen_size + '_' + this.props.orientation : '')}>
                        <img className={"profile_picture-first-page_" + this.props.screen_size +
                            (this.props.screen_size !== 'large' ?
                                ' profile_picture-first-page_' + this.props.screen_size + '_' + this.props.orientation : '')}
                             src={profile_picture} alt=''/>
                    </div>
                    <div
                        className={'introduction_div_' + this.props.styling + " introduction_div introduction_div_" +
                            this.props.screen_size}>
                        {(this.props.screen_size !== 'large' && this.props.orientation === 'vertical') &&
                            <React.Fragment>
                                <h3 className={'heading2_' + this.props.styling}>Hi, I'm Florian</h3>
                                <br/>
                            </React.Fragment>
                        }
                        <divg
                            className={'self_description_div self-description-div_' + this.props.styling + ' self_description_div_' + this.props.screen_size}>
                            <h3 className={'h3_'+ this.props.screen_size}> Research and Career</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                I’m a PhD student at the University of Passau in Germany, working on novel MPSoC
                                architectures with reconfigurable hardware. My dissertation focuses on developing unique
                                device fingerprints in emerging hardware architectures, derived from emerging technologies like
                                MRAM, FRAM, and ReRAM memory. I’ve also explored these fingerprints in nanomaterials,
                                such as custom-produced carbon nanotube field-effect transistors and memristors.

                                Alongside my research, I’m part of the autotech.agil project, where I work on
                                enhancing security and ensuring deterministic timing in automotive networks built
                                on cutting-edge MPSoC-based architectures.
                            </div>

                          <h3 className={'h3_'+ this.props.screen_size}>Personal Activities</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                I enjoy biking around Passau, hiking in the Alps during the summer, and skiing
                                in the winter. I also fly small airplanes around Vilshofen and have a passion for photography.
                            </div>

                            <h3 className={'h3_'+ this.props.screen_size}>Contact</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                Please feel free to contact me by sending a mail to Florian.Frank(at)uni-passau.de.
                            </div>
                        </divg>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Introduction;
import Page from "../Page";
import CVTable from "./page_elements/about_me/CVTable";

import "../../css/sites/AboutMe.css"

import profile_picture from '../../../assets/ProfilePicture.jpg'
import React from "react";


export class AboutMe extends Page {
a
    constructor(props) {
        super(props);
        window.about_me_component = this;

        this.state = {
            styling: props.styling
        }

        this.academic_elements = [{"title": "Munich University of Applied Sciences", "start": 2013, "end": 2018},
            {"title": "B. Sc. Computer Science", "start": "", "end": 2018},
            {"title": "University of Passau", "start": 2018, "end": 2020},
            {"title": "M. Sc. Computer Science", "start": "", "end": 2020},
            {"title": "Research Assistant (Chair of Computer Engineering Passau)", "start": 2020, "end": "now"}]

        this.carrier_elements = [{"title": "Apprenticeship at Deutsche Telekom", "start": 2010, "end": 2013},
            {"title": "Working Student at Micro Epsilon GmbH. and Co. KG.", "start": 2014, "end": 2020}]

        this.bio_text =
            "I’m a PhD student at the University of Passau in Germany, focusing on hardware security, " +
            "with an emphasis on Physical Unclonable Functions (PUFs) and emerging memory technologies like MRAM, " +
            "FRAM, and ReRAM. My dissertation explores the development of unique device fingerprints in emerging hardware " +
            "architectures, derived from these memory technologies. I have also investigated these fingerprints in " +
            "nanomaterials such as custom-produced carbon nanotube field-effect transistors and memristors. " +
            "In addition to my research, I’m part of the autotech.agil project, where I work on developing solutions " +
            "to enhance security and ensure deterministic timing in automotive networks based on novel MPSoC architectures with reconfigurable hardware."

    }

    /**
     * Sets the correct size of the different div-containers upon mounting this react component.
     */
    componentDidMount() {
        super.componentDidMount()
        document.getElementsByClassName("master_div")[0].classList.toggle("page_load");
    }

    render() {
        return (
            <div className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={'main_content_div main_content_div_' + this.state.screen_size}>
                <h2 className={"heading1_" + this.state.styling}>About Me</h2>
                    <div className={"subdiv_" + this.state.styling}>
                        <img className="profile_picture" src={profile_picture} alt=""/>

                        <table className="cv_table">
                            <tr>
                                <th colSpan='2' className={'heading2 heading2_' + this.state.styling}>Research
                                    Interests
                                </th>
                            </tr>
                            <tr>
                                <td colSpan='2' className='bio_table_row'>
                                    <div
                                        className={'default_text_div_' + this.state.screen_size + " normal_text_" + this.state.styling}>{this.bio_text}</div>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan='2' className={'heading2 heading2_' + this.state.styling}>Academic</th>
                            </tr>
                            <CVTable styling={this.state.styling} screen_size={this.state.screen_size}
                                     cv_elements={this.academic_elements}/>
                            <tr>
                                <th colSpan='2' className={'heading2 heading2_' + this.state.styling}>Career</th>
                            </tr>
                            <CVTable styling={this.state.styling} screen_size={this.state.screen_size}
                                     cv_elements={this.carrier_elements}/>
                            <tr>
                                <th colSpan='2' className={'heading2 heading2_' + this.state.styling}>Research
                                    Interests
                                </th>
                            </tr>
                            <tr className={"tr_cv_content-" + this.state.styling}>
                                <td className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}
                                    id={'about_me_td_' + this.state.styling}>Hardware Security
                                </td>
                                <td className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}
                                    id={'about_me_td_' + this.state.styling}>Reconfigurable Hardware
                                </td>
                            </tr>
                            <tr className={"tr_cv_content_" + this.state.styling}>
                                <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>Security
                                    in Nanomaterials
                                </td>
                                <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>Automotive
                                    Soft- and Hardware
                                </td>
                            </tr>
                            {/*   <tr>
                                <th colSpan='2' className={'heading2 heading2_' + this.state.styling}>Other Interests
                                </th>
                            </tr>
                             <tr className={'tr_cv_content'}>
                                <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>I
                                    like
                                    to go cycling in the vicinity of Passau.
                                </td>
                                <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>During
                                    the winter I often go skiing in the alps.
                                </td>
                            </tr>
                                <tr className="tr_cv_content">
                                <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>I
                                    also like to travel to various locations
                                    around the world.
                                </td>
                               <td id={'about_me_td_' + this.state.styling}
                                    className={'default_text_div_' + this.state.screen_size + ' td_cv_content_first_row'}>I'm
                                    owner of a private pilot license.
                                </td>
                            </tr>*/}
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutMe

import React from 'react'

import Page from "../Page";
import BlogArticlePreview from "./page_elements/blog/BlogArticlePreview";
import BlogEntry from '../../jsx/blog_model/BlogEntry'
import SwitchMatrixMarkdown from '../../../assets/blog_entries/switch_matrix/switch_matrix.md'
import SwitchMatrixFigure from '../../../assets/blog_entries/switch_matrix/switch_matrix_figure.jpg'
import TestAutomationFigure from '../../../assets/blog_entries/test_automation/structure_overview.jpg'
import TestAutomationMarkdown from '../../../assets/blog_entries/test_automation/puf_test_automation.md'

import award_ceremony_ieee_nano from '../../../assets/blog_entries/ieee_nano_2023/best_paper_ceremony.jpg'; // with import
import asia_css_talk from '../../../assets/blog_entries/asiaccs_2024/asia_ccs1_full.jpg'; // with import

import asiaccs_marina_bay_sands from '../../../assets/blog_entries/asiaccs_2024/marina_bay_sands.jpg'; // with import
import asiaccs_best_paper from '../../../assets/blog_entries/asiaccs_2024/best_paper_ceremony.jpg'; // with import
import gardens_by_the_bay from '../../../assets/blog_entries/asiaccs_2024/gardens_by_the_bay.jpg'; // with import
import chinatown_temple from '../../../assets/blog_entries/asiaccs_2024/temple.jpg'; // with import
import marina_bay_sands2 from '../../../assets/blog_entries/asiaccs_2024/marina_bay_sands2.jpg'; // with import


import "../../css/sites/BlogArticle.css"
import "../../css/Markdown.css"


export class Blog extends Page {

    constructor(props) {
        super(props);
        window.blog_component = this;

        this.state = {
            styling: props.styling,
        }

        this.blog_entry_asiaccs = new BlogEntry([(<React.Fragment>
                From July 1 to July 5, 2024, I attended the ACM Asia Conference on Computer and Communications Security (ASIACCS 2024) in Singapore. During this conference, I presented our paper titled "Secure Data-Binding in FPGA-based Hardware Architectures Utilizing PUFs" (<a className={'a_' + this.state.styling} href='https://doi.org/10.1145/3634737.3656996'>Link</a>).
                On June 29, I departed from Munich to Singapore for the ASIACCS conference, arriving on June 30 after a stopover in Doha.
                I checked into my hotel and registered for the conference on July 1, followed by attending the welcome reception later that day.
                The welcome reception was held on Sentosa, a small island south of Singapore, where I met colleagues from various IT security fields, including many from German-speaking regions.
                Over the next few days, I attended workshops and sessions on topics like Cyber-Physical Systems and Hardware Security, which were particularly relevant to my research.
                On Wednesday, July 3, I presented our work in a 15-minute session focused on hardware security. I received several questions, which I was able to address. During the coffee break, I engaged in discussions with participants about potential improvements.
                Notably, our paper won the Best-Paper Award at the conference dinner on July 4.
                In addition to attending conference sessions, I also found time to explore some of Singapore's main attractions, including the Marina Bay Sands hotel, Gardens by the Bay, and various temples in Chinatown and Little India.
            </React.Fragment>)], [

                asiaccs_best_paper,
                asia_css_talk,
                gardens_by_the_bay,
                marina_bay_sands2,
                chinatown_temple
            ],
            [
                'Best Paper Award at the Conference Dinner',
                'Talk during the Hardware Security session',
                'Lightshow at the Gardens by the Bay',
                'Merlion and Marina Bay Sands',
                'Thian Hock Keng temple, Chinatown'
            ])


        this.blog_entry_ieee_nano = new BlogEntry([(
                <React.Fragment> I had the privilege of attending the IEEE NANO (IEEE
                    International Conference on Nanotechnology) 2023,
                    held from July 2nd to July 5th in Jeju Island, South Korea.
                    During this event, I presented our paper titled
                    "CNT-PUFs: Highly Robust Physical Unclonable Functions based on Carbon
                    Nanotubes" <a className={'a_' + this.state.styling}
                                  href='https://ieeexplore.ieee.org/document/10231160'>(Link).</a></React.Fragment>), (
                <React.Fragment> &nbsp;The conference was hosted at the Ramada Plaza Hotel in Jeju, directly
                    located on the coast with multiple parallel session all related
                    to nanotechnology.
                    Our paper presentation was on Monday the 3th of July as one of the
                    finalist for the conference's Best Paper Award, which we could win,
                    as announced during the banquet on the 4th of July.
                    <br/><br/>

                    Finally, I had some time to extend this journal by a short private
                    trip to explore the island of Jeju, as well as South Koreas
                    capital, Seoul.</React.Fragment>)], [award_ceremony_ieee_nano,
                'https://live.staticflickr.com/65535/53161318570_15fb782213_w.jpg',
                'https://live.staticflickr.com/65535/53161382893_4df9ff9296_w.jpg',
                'https://live.staticflickr.com/65535/53161142805_3355371f89_w.jpg',
                'https://live.staticflickr.com/65535/53160889991_5a388aaaa1_w.jpg'],
            ['Best Paper Award, Ramada Plaza by Wyndham Jeju',
                'Deoksugung palace, Seoul',
                'Bukchon Hanok village, Seoul',
                'Yongyeon Pond, Jeju-Do',
                'Sunset, Jeju-Do.'])

        this.blog_entry_date = new BlogEntry([(<React.Fragment>From the 17th to the 19th of April
                I participated the DATE
                (Design, Automation and Test in Europe Conference) conference 2023.
                This conference included the Workshop on Nano Security: From Nano-Electronics
                to Secure Systems (<a className={'a_' + this.state.styling}
                                      href='https://spp-nanosecurity.uni-stuttgart.de/nanosec23'>DATE 23</a>)
                were I had the opportunity to present our project PUFMem during a dedicated poster session.
                Throughout the conference, I joined a wide area of different sessions, spanning from topics on FPGA
                technology,
                to nanomaterials up to machine learning topics.
                <br/>
                Lastly, I found some time to explore the city of Antwerp and made a quick stop in Brussels on my way back to
                Passau.
            </React.Fragment>)], [
                'https://live.staticflickr.com/65535/53178589885_1c80e6f8cc_w.jpg',
                'https://live.staticflickr.com/65535/53177244939_b540c7cf87_w.jpg',
                'https://live.staticflickr.com/65535/53177536133_153e998bc1_w.jpg',
                'https://live.staticflickr.com/65535/53177478530_0418cbca1f_w.jpg'
            ],
            [
                'Opening DATE 2023, Antwerp',
                'Grote Markt, Antwerp',
                'Museum aan de Stroom, Antwerp',
                'Atomium, Brussels'])

        this.blog_entry_esorics = new BlogEntry([(<React.Fragment>
                From the 26th to 30th of September 2022 I attended the ESORICS (European Symposium on Research in
                Computer
                Security) conference in Copenhagen, Denmark. I presented our paper "Using Memristor Arrays as Physical
                Unclonable Functions"(<a className={'a_' + this.state.styling}
                                         href='http://dx.doi.org/10.1007/978-3-031-17143-7_13'>Link</a>), describing an
                approach using Descrete Fourier Transformation as well as neuronal networks, to identify single
                hysteresis loop originating from measurements of single memristors. This identification can be utilized,
                for example in authentication protocols.

                I attended several sessions, as well as the conference dinner, allowing for a fruitful exchange between
                other researchers in this domain. Furthermore, I found some time to explore the city.

            </React.Fragment>)], [
                'https://live.staticflickr.com/65535/53181023439_e42277651f_w.jpg',
                //'https://live.staticflickr.com/65535/53180833271_b46a3aa969_b.jpg',
                'https://live.staticflickr.com/65535/53181331088_f3e93de063_w.jpg',
                'https://live.staticflickr.com/65535/53180833271_5bdd43c4a3_w.jpg'
            ],
            [
                'Kopenhagen from the top of vor Frelsers Kirke',
                'Castle Amalienborg, Copenhagen',
                'The Little Mermaid, Copenhagen'
            ])


        this.a = <div>From June 8th to June 10th, 2022, I had the privilege of attending the International Conference on
            Modern Circuits and Systems Technologies (MOCAST) in the field of Electronics and Communication. During this
            event, I had the opportunity to present
            our research paper titled "A Dedicated Mixed-Signal Characterization and Testing Framework for Novel Digital
            Security Circuits Utilizing Carbon-Nanotube-Based Physical Unclonable Functions" (You can find the paper
            here).

            Our paper focuses on an innovative measurement setup that facilitates the automated readout of
            Carbon-Nanotube-based Field Effect Transistors. We achieved this through the implementation of a
            meticulously designed switch matrix, akin to a Source Measure Unit (SMU).

            The presentation of our paper began with a concise 5-minute overview, providing a glimpse into our
            groundbreaking work. Following the presentation, an interactive session ensued, allowing for in-depth
            discussions and exchanges of ideas with fellow conference participants, enriching the overall experience of
            sharing and learning from our peers.</div>

        this.blog_entry_mocast = new BlogEntry([(<React.Fragment>
                From the 8th until the 10th of June 2022 I attended in the International Conference on Modern
                Circuits and Systems Technologies (MOCAST) on Electronics and Communication to present our paper
                A Dedicated Mixed-Signal Characterisation and Testing Framework for Novel Digital Security Circuits That Use
                Carbon-Nanotube-Based Physical Unclonable Functions (<a className={'a_' + this.state.styling}
                                                                        href='https://ieeexplore.ieee.org/document/9837567'>Link</a>).
                Our paper focuses on a measurement setup that facilitates the automated readout of
                Carbon-Nanotube-based Field Effect Transistors. We achieved this through the implementation of a
                self designed switch matrix, connected to a Source Measure Unit (SMU).

                The presentation of our paper began with a 5 minute oral presentation. Followed by the possibility to
                discuss
                our work directly with the participants of the conference in a dedicated poster sessions.
            </React.Fragment>)], [
                'https://live.staticflickr.com/65535/53160945564_189572b89f_w.jpg',
                'https://live.staticflickr.com/65535/53185478199_3687bf48d0_w.jpg',
                'https://live.staticflickr.com/65535/53185612555_d7740d69c7_w.jpg',

            ],
            [
                'Alexander von Humboldt, Bremen',
                'Böttcher Gasse, Bremen',
                'Beck´s Mühle am Wall, Bremen'
            ])

        this.blog_entry_nessy = new BlogEntry([(<React.Fragment>
                From the 11th and 12th of November 2021 I joined the NESSY (Nano-Electronics for Secure Systems) fall school
                which was co-organized with the CARDIS (Smart Card Research and Advanced Application Conference) in the city
                of Lübeck.
                During this event, I participated in various workshops, such as one describing hardware attacks on DRAMs
                exploiting
                Row-Hammering effects or attacks utilizing FPGA based ring-oscillators. One part of this event was a poster
                session arranged by the SPP (<a className={'a_'+this.state.styling}
                                                href='https://spp-nanosecurity.uni-stuttgart.de/de'>DFG Schwerpunktprogramm Nano Security</a>), in which the
                project PUFMem is also a part of. Therefore, I had the opportunity to present the achievements
                and further goals of the PUFMem project. In general the poster session provides a platform for networking
                between
                the other SPP members, aiming to establish potential collaborations.

            </React.Fragment>)], [
                'https://live.staticflickr.com/65535/53160954114_3717925b16_w.jpg',
                'https://live.staticflickr.com/65535/53239184597_11a1272e56_w.jpg',
                'https://live.staticflickr.com/65535/53240558125_0d0a6ab4f3_w.jpg'
            ],
            [
                'Holstentor, Lübeck',
                'Oldtown, Lübeck',
                'Burgtor, Lübeck'
            ])

        this.blog_entry_switch_matrix = new BlogEntry([(<React.Fragment>
                A self-created PCB has been developed for the automatic readout of carbon nanotube field-effect
                transistors arranged in a 12x12 matrix structure. To ensure accurate measurements and prevent
                leakage currents, relays are utilized to select both a column and a row. In order to maintain
                uniform wire lengths and accommodate currents in the sub-nanoampere range (less than 1 nA), all wires
                have been designed to be of equal length. The relays are driven by an ALN2003APW relay driver,
                which is connected to two 16x multiplexers. These multiplexers are controlled by an Arduino Nano
                microcontroller.
                To minimize interference and avoid measurement distortions, a clear separation between the control
                and measurement circuitry has been implemented.<br/><br/>

                The PCB design allows for the interchangeability of different carbon nanotube arrays via a PCI M.2
                interface.
                Additionally, an additional self-created PCB with an Immersion Gold Surface Finish has been utilized.
                This PCB facilitates wire bonding of the row, column, and gate connections for each individual transistor in
                the CNT matrix.<br/>
                <br/>

                F. Frank et al., "A Dedicated Mixed-Signal Characterisation and Testing Framework for Novel Digital Security
                Circuits That Use Carbon-Nanotube-Based Physical Unclonable Functions," 2022 11th International Conference
                on Modern Circuits and Systems Technologies (MOCAST), 2022, pp. 1-4, <br/>doi: <a
                className={'a_' + this.state.styling}
                href="https://ieeexplore.ieee.org/document/9837567">10.1109/MOCAST54814.2022.9837567</a>

            </React.Fragment>)], [
                SwitchMatrixFigure,
            ],
            [
                'Switch Matrix with connected daughter board',
            ])

        this.block_entry_instrument_control_lib = new BlogEntry([(<React.Fragment>
                <h3 className={'projects_header_' + this.state.styling}>Instrument control lib</h3>
                The "Instrument control lib" is a library providing functions to configure oscilloscopes,
                signal generators, DC power supplies, and other measurement devices. The library is used in
                different projects, to automate test executions.
                The code is available on following repository <a className={'a_' + this.state.styling}
                                                                 href='https://github.com/FlorianFrank/instrument_control_lib'>Instrument
                Control Lib</a>.
                <br/><br/>

                It provides the following functionality:<br/>
                <ul>
                    <li>Establishing a network connection and identifying the different types of devices.</li>
                    <li>Autodiscovery of devices in the network</li>
                    <li>Execution of commands on devices by a generic interface. For example, setting the voltage on a DC
                        power supply,
                        setting voltage limits on SMUs, or gathering the current data of an oscilloscope.
                    </li>
                    <li>Platform independent implementation (tested on Windows, MAC OS, and Ubuntu).</li>
                    <li>A Python and C++ interface is provided</li>
                </ul>
                For more information, please visit <a className={'a_' + this.state.styling}
                                                      href="https://github.com/FlorianFrank/instrument_control_lib">Documentation</a>.
            </React.Fragment>)], [
                'https://github.com/FlorianFrank/instrument_control_lib/raw/master/docs_and_specs/figures/Logo.svg',
            ],
            [
                '',
            ])

        this.instrument_control_lib_description = <React.Fragment>
            <h3 className={'projects_header_' + this.state.styling}>Instrument control lib</h3>
            The "Instrument control lib" is a library providing functions to configure oscilloscopes,
            signal generators, DC power supplies, and other measurement devices. The library is used in
            different projects, to automate test executions.
            The code is available on following repository <a className={'a_' + this.state.styling}
                                                             href='https://github.com/FlorianFrank/instrument_control_lib'>Instrument
            Control Lib</a>.
            <br/><br/>

            It provides the following functionality:<br/>
            <ul>
                <li>Establishing a network connection and identifying the different types of devices.</li>
                <li>Autodiscovery of devices in the network</li>
                <li>Execution of commands on devices by a generic interface. For example, setting the voltage on a DC
                    power supply,
                    setting voltage limits on SMUs, or gathering the current data of an oscilloscope.
                </li>
                <li>Platform independent implementation (tested on Windows, MAC OS, and Ubuntu).</li>
                <li>A Python and C++ interface is provided</li>
            </ul>
            For more information, please visit <a className={'a_' + this.state.styling}
                                                  href="https://github.com/FlorianFrank/instrument_control_lib">Documentation</a>.
        </React.Fragment>;

        this.block_entry_desktop = function (entry) {
            return <div>
                <table style={{width: '100%'}}>
                    <tr>
                        <td style={{width: '50%'}}>
                            {entry.texts[0]}
                            {entry.texts[1]}
                        </td>
                        <td>
                            {entry.captions.length >= 1 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[0]} alt={entry.captions[0]}/>
                                    <figcaption>{entry.captions[0]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>


                    <tr>
                        <td>
                            {entry.captions.length >= 2 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[1]} alt={entry.captions[1]}/>
                                    <figcaption>{entry.captions[1]}</figcaption>
                                </figure> : ''}
                        </td>
                        <td>
                            {entry.captions.length >= 3 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[2]} alt={entry.captions[2]}/>
                                    <figcaption>{entry.captions[2]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {entry.captions.length >= 4 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[3]} alt={entry.captions[3]}/>
                                    <figcaption>{entry.captions[3]}</figcaption>
                                </figure> : ''}
                        </td>
                        <td>
                            {entry.captions.length >= 5 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[4]} alt={entry.captions[4]}/>
                                    <figcaption>{entry.captions[4]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                </table>
            </div>
        }

        this.block_entry_mobile = function (entry) {
            return <div>
                <table>
                    <tr>
                        <td style={{'text-align': 'justify'}}>
                            {entry.texts[0]}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {entry.captions.length >= 1 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                <img style={{width: '100%'}}
                                     src={entry.figure_urls[0]} alt={entry.figure_urls[0]}/>
                                <figcaption>{entry.captions[0]}</figcaption>
                            </figure> : ''}
                        </td>
                    </tr>

                    <tr>
                        <td style={{'text-align': 'justify'}}>
                            {entry.texts[1]}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {entry.captions.length >= 2 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[1]} alt={entry.figure_urls[1]}/>
                                    <figcaption>{entry.captions[1]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {entry.captions.length >= 3 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[2]} alt={entry.figure_urls[2]}/>
                                    <figcaption>{entry.captions[2]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {entry.captions.length >= 4 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[3]} alt={entry.figure_urls[3]}/>
                                    <figcaption>{entry.captions[3]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {entry.captions.length >= 5 ?
                                <figure style={{width: '100%', 'margin-left': '0'}}>
                                    <img style={{width: '100%'}}
                                         src={entry.figure_urls[4]} alt={entry.figure_urls[1]}/>
                                    <figcaption>{entry.captions[4]}</figcaption>
                                </figure> : ''}
                        </td>
                    </tr>
                </table>
            </div>
        }

    }

    change_visibility(id) {
        document.getElementsByClassName("main_div_blog_article_visibility")[0].classList.remove("hidden")
        document.getElementsByClassName("main_div_blog_article_visibility")[0].classList.add("visible")

    }

    setStyle(style) {
        this.setState({styling: style})
    }

    /**
     * Renders the Blog page.
     * @returns {JSX.Element}
     */
    render() {
        return (
            <div className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={'main_content_div_' + this.state.screen_size}>
                    <h2 className={"heading1_" + this.state.styling}>Blog</h2>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={4}
                                        category='Implementation'
                                        title='PUF Automation Hub' hashtags='#implementation #PUFs #Automation #Software'
                                        short_description='Implementation of a PUF Test Automation System'
                                        markdown={true}
                                        innerConnent={TestAutomationMarkdown}
                                        outer_cover_floating={true}
                                        picture_url={TestAutomationFigure}
                                        date={'29.07.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={0}
                                        category='Conference'
                                        title='AsiaCCS 2024, Singapore'
                                        hashtags='#travel #conference' short_description='Presentation of our paper Secure Data-Binding in FPGA-based Hardware Architectures
                                                  utilizing PUFs at the IEEE Nano conference 2023 in Singapore.'
                                        markdown={false}
                                        innerConnent={(this.state.screen_size === 'mobile' ||
                                            this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_asiaccs) :
                                            this.block_entry_desktop(this.blog_entry_asiaccs)}
                                        picture_url={asiaccs_marina_bay_sands}
                                        date={'11.08.2024'}/>


                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={0}
                                        category='Conference'
                                        title='IEEE Nano 2023, Jeju Do'
                                        hashtags='#travel #conference' short_description='Presentation of our paper CNT-PUFs: Highly Robust Physical Unclonable Functions
                    based on Carbon Nanotubes at the IEEE Nano conference 2023 in Jeju Do, South Korea.'
                                        markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_ieee_nano) :
                                 this.block_entry_desktop(this.blog_entry_ieee_nano)}
                             picture_url={'https://live.staticflickr.com/65535/53161142805_3355371f89_w.jpg'}
                             date={'03.09.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={4}
                             category='Implementation'
                             title='Instrument Control Lib' hashtags='#implementation #instruments'
                             short_description='C++ implementation of a library to control SMUs, Oscilloscopes, Signal generators, etc.'
                             markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.block_entry_instrument_control_lib) :
                                 this.block_entry_desktop(this.block_entry_instrument_control_lib)}
                             picture_url={'https://github.com/FlorianFrank/instrument_control_lib/raw/master/docs_and_specs/figures/Logo.svg'}
                             date={'26.09.2023'}/>


                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={1}
                             category='Conference'
                             title='DATE 2023, Antwerp' hashtags='#travel #conference'
                             short_description='Presentation of the PUFMem Project at the DATE conference 2023 in Antwerp, Belgum.'
                             markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_date) :
                                 this.block_entry_desktop(this.blog_entry_date)}
                             picture_url={'https://live.staticflickr.com/65535/53164656829_90e5d5db34_w.jpg'}
                             date={'03.09.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={2}
                             category='Conference'
                             title='ESORICS 2023, Copenhagen' hashtags='#travel #conference'
                             short_description='Presentation of our paper Using Memristor Arrays as Physical Unclonable Functions at the ESORICS conference 2022 in Kopenhagen, Denmark.'
                             markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_esorics) :
                                 this.block_entry_desktop(this.blog_entry_esorics)}
                             picture_url={'https://live.staticflickr.com/65535/53160722286_2550947fef_w.jpg'}
                             date={'03.09.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={4}
                             category='Implementation'
                             title='NANOSEC Switch Matrix' hashtags='#implementation #pcb'
                             short_description='Implementation of a PCB for an automated readout of CNT-FETs'
                             markdown={true}
                             innerConnent={SwitchMatrixMarkdown}
                             outer_cover_floating={true}
                             picture_url={SwitchMatrixFigure}
                             date={'29.07.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={3}
                             category='Conference'
                             title='MOCAST 2022, Bremen' hashtags='#travel #conference'
                             short_description='Presentation of our paper A Dedicated Mixed-Signal Characterisation and Testing Framework for Novel Digital Security Circuits That Use Carbon-Nanotube-Based Physical Unclonable Functions at the MOCAST conference 2022 in Bremen, Germany.'
                             markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_mocast) :
                                 this.block_entry_desktop(this.blog_entry_mocast)}
                             picture_url={'https://live.staticflickr.com/65535/53160945564_189572b89f_w.jpg'}
                             date={'29.09.2023'}/>

                    <BlogArticlePreview screen_size={this.state.screen_size} styling={this.state.styling} offset={4}
                             category='Fall School'
                             title='NESSY 2021, Lübeck' hashtags='#travel #conference'
                             short_description='Presentation of the PUFMem Project at the NESSY fall school 2021 in Lübeck, Germany.'
                             markdown={false}
                             innerConnent={(this.state.screen_size === 'mobile' ||
                                 this.state.screen_size === 'small') ? this.block_entry_mobile(this.blog_entry_nessy) :
                                 this.block_entry_desktop(this.blog_entry_nessy)}
                             picture_url={'https://live.staticflickr.com/65535/53160954114_3717925b16_w.jpg'}
                             date={'03.09.2023'}/>
                </div>
            </div>
        )
    }
}

export default Blog
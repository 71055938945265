import Page from "../Page";
import SendThesisRequest from "./page_elements/teaching/SendThesisRequest";

import "../../css/sites/Teaching.css"
import Button from "../Button";

export class Teaching extends Page {

    constructor(props) {
        super(props);
        window.teaching_component = this;

        this.state = {
            styling: props.styling,
            selected_topic: 'non selected',
            mobile: false
        }
    }


    showContactForm(title) {
        this.toggle_single_element("content_form_div", "animate", 'className')
        this.add_single_element("content_form_div", (this.state.mobile) ? 'visible_mobile' : 'visible', 'className')
        this.remove_single_element("content_form_div", 'invisible', 'className')
        this.setState({selected_topic: title})
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        return (
            <div>
                <SendThesisRequest styling={this.state.styling} topic_name={this.state.selected_topic}
                                   screen_size={this.state.screen_size}/>
                <div
                    className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                    <div className={'main_content_div_' + this.state.screen_size}>
                        <h2 className={'heading1_' + this.state.styling}>Teaching</h2>
                        <div className={"subdiv_" + this.state.styling}>
                            <table className="teaching_table">

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Winter Term
                                        2024/2025
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=2068483a8336822edc5e51cb3695606d'>Master
                                            Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=480d948656a1afb24b7df04363619f74'>
                                            Technische Informatik</a>
                                    </td>
                                </tr>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Summer Term
                                        2024
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=64ab3d3decbcdedb56f11f08f1f2853d'>Master
                                            Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=d8fcc8c2192f86bd5eb3f8b888fa896d'>Advanced
                                            Security Engineering Lab</a>
                                    </td>
                                </tr>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Winter Term
                                        2023/2024
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=64a81d46e7597a93177c4cd93cb71700'>Master
                                            Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=11fe8171ea06731f1934ab8f868ac68f'>Advanced
                                            Security Engineering Lab</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Summer Term
                                        2023
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=0cf8d750e8c318a20fe39e8788c9b57c'>
                                            Rechnerarchitekturen</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=2642e701a944d203fd9c15ebecbb73ed'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=3007cc770966613622cd83525a32ab6f'>
                                            Advanced Security Engineering Lab</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Winter Term
                                        2022/2023
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=d80433e6f07bda20000750186151b055'>
                                            Technische Informatik</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=9b76393da42645cf1f8ca4dcbfdc066c'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Summer Term
                                        2022
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=99ee18ddadfbecbd722f61843a3a5b77'>
                                            Rechnerarchitekturen</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=353d112ed6971886bbb5243fd77ef4a0'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Winter Term
                                        2021/2022
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=7ab4184d9f33501243bbd7bdbed45474'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=a270dd6060b5756d2d617bc60d8d5fa0'>
                                            Hardware-oriented Security</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Summer Term
                                        2021
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=d694f84b71e2291b8532aa021c927fa6'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=2bd3b512ab239a9dad6ec8b487d1f38f'>
                                            Advanced Security Engineering Lab</a>
                                    </td>
                                </tr>


                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Winter Term
                                        2020/2021
                                    </th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Seminar
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=642862aa306199bf09f1819dcb0d3a40'>
                                            Master Seminar Security Engineering</a>
                                    </td>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Exercise
                                    </td>
                                    <td className="date_row td_cv_content_first_row">
                                        <a className={'a_' + this.state.styling + ' default_text_div_' + this.state.screen_size}
                                           href='https://studip.uni-passau.de/studip/dispatch.php/course/overview?cid=d97e2b61ae1140bbca5e6e0ed05fd9e7'>
                                            Hardware-oriented Security</a>
                                    </td>
                                </tr>
                            </table>

                            <h2 className={'heading1_' + this.state.styling}>Thesis</h2>

                            <table className="teaching_table">
                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Open Topics
                                    </th>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Evaluation of TRNG behavior on non-volatile memory modules (Bachelor's or
                                        Master's Thesis)
                                    </td>
                                    <td className='td_cv_content_first_row'>
                                        <Button
                                            button_style={'button_' + this.state.styling + ' button_' + this.state.screen_size}
                                            value="Request further information" id="button1"
                                            onClick={() => {
                                                this.showContactForm('Evaluation of TRNG behavior on non-volatile memory modules')
                                            }}/>
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Physical Unclonable Functions based on voltages pulses (Bachelor's or Master's
                                        Thesis)
                                    </td>
                                    <td className='td_cv_content_first_row'>
                                    <Button
                                            button_style={'button_' + this.state.styling + ' button_' + this.state.screen_size}
                                            value="Request further information" id="button1"
                                            onClick={() => {
                                                this.showContactForm('Using memristive devices as Physical Unclonable Functions')
                                            }}/>
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Hardware-accelerated package filtering and prioritizing in automotive architectures (Master's Thesis)
                                    </td>
                                    <td className='td_cv_content_first_row'>
                                        <Button
                                            button_style={'button_' + this.state.styling + ' button_' + this.state.screen_size}
                                            value="Request further information" id="button1"
                                            onClick={() => {
                                                this.showContactForm('')
                                            }}/>
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row default_text_div_' + this.state.screen_size}>
                                        Implementing Features for a PUF Testing Environment (Bachelor's Thesis)
                                    </td>
                                    <td className='td_cv_content_first_row'>
                                        <Button
                                            button_style={'button_' + this.state.styling + ' button_' + this.state.screen_size}
                                            value="Request further information" id="button1"
                                            onClick={() => {
                                                this.showContactForm('')
                                            }}/>
                                    </td>
                                </tr>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Ongoing</th>
                                </tr>
                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Investigation of Voltage and Temperature variations on Ferroelectric RAM
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Physical Unclonable Functions based on read and write latency variations
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Expanding DDS-Security to Resource-Constrained Automotive Devices
                                    </td>
                                </tr>


                                <br/>
                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Finished</th>
                                </tr>


                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        An Architecture for Freezing the Partition State during Dynamic Partial
                                        Self-Reconfiguration in Xilinx Series 7 FPGAs
                                    </td>
                                </tr>


                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Towards Efficient PUF Testing: An Automated Platform for Measurements and
                                        Evaluation.
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Investigation of Commercial-Off-the-Shelf
                                        Ferroelectric RAM and Resistive RAM for
                                        their use as Physical Unclonable Functions
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Extracting Physical Unclonable Function
                                        behaviour from Non-Volatile memories under
                                        different environmental conditions: An
                                        example of FRAM and MRAM
                                    </td>
                                </tr>

                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>
                                        Implementation of a FPGA-based PUF for an automotive key distribution protocol
                                    </td>
                                </tr>


                                <tr className={'tr_cv_content-' + this.state.styling}>
                                    <td className={'td_cv_content_first_row  default_text_div_' + this.state.screen_size} colSpan='2'>

                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Teaching
